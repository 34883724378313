<template>
<div class="mt-2">
  <template v-if="sub">
    <b-button variant="secondary" @click="sub=false">
      <b-icon icon="arrow90deg-left"/>
    </b-button>
    <hr/>

    <b-card 
        v-if="sub.name || sub.category || sub.muatan"
      title="Data Pustaka Risiko" class="mt-2"
    >
      <div class="table-reponsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Data Sebelumnya</th>
              <th>Data Yang Diajukan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="sub.name">
              <th><b>Nama Risiko</b></th>
              <td>{{ sub.name.old || '-' }}</td>
              <td>
                {{ sub.name.new||'X' }}
              </td>
            </tr>
            <tr v-if="sub.muatan">
              <th><b>Muatan</b></th>
              <td>{{ sub.muatan.old || '-' }}</td>
              <td>
                {{ sub.muatan.new||'X' }}
              </td>
            </tr>
            <tr v-if="sub.category">
              <th><b>Muatan</b></th>
              <td>
                {{ sub.category_name.old||'-' }}
              </td>
              <td >
                {{ sub.category_name.new||'X' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <VTab v-if="(sub.sebab||[]).length || (sub.dampak||[]).length || (sub.kendali||[]).length">
      <template slot="link">
        <VTabLink v-if="(sub.sebab||[]).length" :step.sync="tabSub" option="sebab">Sebab</VTabLink>
        <VTabLink v-if="(sub.dampak||[]).length" :step.sync="tabSub" option="dampak">Dampak</VTabLink>
        <VTabLink v-if="(sub.kendali||[]).length" :step.sync="tabSub" option="kendali">Pengendalian</VTabLink>
      </template>
      <template slot="content">
        <VTabContent :step.sync="tabSub" option="sebab">
          <b-card title="Data Sebab" class="mt-2" style="max-height:800px;overflow:auto;">
            <div class="table-reponsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Data Sebelumnya</th>
                    <th>Data Yang Diajukan</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in (sub.sebab||[])">
                    <tr :key="k+'a'">
                      <td colspan="3">
                        Kode: {{ v.code }}
                      </td>
                    </tr>
                    <tr v-if="v.name" :key="k+'b'">
                      <th><b>Nama Risiko</b></th>
                      <td>
                        <span v-if="v.name.old==v.name.new">-</span>
                        <span v-else>{{ v.name.old||'-' }}</span>
                      </td>
                      <td  >
                        {{ v.name.new||'X' }}
                      </td>
                    </tr>
                    <tr v-if="v.category" :key="k+'b'">
                      <th><b>Kategori</b></th>
                      <td>
                        <span v-if="v.category_name.old==v.category_name.new">-</span>
                        <span v-else>{{ v.category_name.old||'-' }}</span>
                      </td>
                      <td  >
                        {{ v.category_name.new||'X' }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-card>
        </VTabContent>
        <VTabContent :step.sync="tabSub" option="dampak">
          <b-card title="Data Dampak" class="mt-2" style="max-height:800px;overflow:auto;">
            <div class="table-reponsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Data Sebelumnya</th>
                    <th>Data Yang Diajukan</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in (sub.dampak||[])">
                    <tr :key="k+'a'">
                      <td colspan="3">
                        Kode: {{ v.code }}
                      </td>
                    </tr>
                    <tr v-if="v.name" :key="k+'b'">
                      <th><b>Nama Risiko</b></th>
                      <td>
                        <span v-if="v.name.old==v.name.new">-</span>
                        <span v-else>{{ v.name.old||'-' }}</span>
                      </td>
                      <td  >
                        {{ v.name.new||'X' }}
                      </td>
                    </tr>
                    <tr v-if="v.category" :key="k+'b'">
                      <th><b>Kategori</b></th>
                      <td>
                        <span v-if="v.category_name.old==v.category_name.new">-</span>
                        <span v-else>{{ v.category_name.old||'-' }}</span>
                      </td>
                      <td  >
                        {{ v.category_name.new||'X' }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-card>
        </VTabContent>
        <VTabContent :step.sync="tabSub" option="kendali">
          <b-card title="Data Pengendalian" class="mt-2" style="max-height:800px;overflow:auto;">
            <div class="table-reponsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Data Sebelumnya</th>
                    <th>Data Yang Diajukan</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in (sub.kendali||[])">
                    <tr :key="k+'a'">
                      <td colspan="3">
                        Kode: {{ v.code }}
                      </td>
                    </tr>
                    <tr v-if="v.name" :key="k+'b'">
                      <th><b>Nama Risiko</b></th>
                      <td>
                        <span v-if="v.name.old==v.name.new">-</span>
                        <span v-else>{{ v.name.old||'-' }}</span>
                      </td>
                      <td  >
                        {{ v.name.new||'X' }}
                      </td>
                    </tr>
                    <tr v-if="v.terkait" :key="k+'d'">
                      <th><b>Terkait</b></th>
                      <td>
                        <span v-if="v.terkait.old==v.terkait.new">-</span>
                        <span v-else-if="v.terkait.old=='sebab'">Sebab</span>
                        <span v-else>{{ v.terkait.old||'-' }}</span>
                      </td>
                      <td >
                        <span v-if="v.terkait.new=='sebab'">Sebab</span>
                        <span v-else>{{ v.terkait.new||'X' }}</span>
                      </td>
                    </tr>
                    <tr v-if="v.category" :key="k+'b'">
                      <th><b>Kategori</b></th>
                      <td>
                        <span v-if="v.category_name.old==v.category_name.new">-</span>
                        <span v-else>{{ v.category_name.old||'-' }}</span>
                      </td>
                      <td  >
                        {{ v.category_name.new||'X' }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-card>
        </VTabContent>
      </template>
    </VTab>

  </template>
  <template v-else>
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Tanggal</th>
            <th>Aktifitas</th>
            <th>Diubah Oleh</th>
            <th>Data Sebelum &amp; Sesudah Diajukan</th>
          </tr>
        </thead>
        <tbody v-if="logs.length">
          <tr v-for="(v,k) in logs" :key="k">
            <td>{{ k+1 }}</td>
            <td>{{ v.mrl_create_date | moment('DD MMMM YYYY HH:mm') }}</td>
            <td>
              {{ v.mrl_activity }}
              <b-card v-if="v.mrl_reason" class="mt-3">
                <p class="m-0">
                  <b>Alasan: </b> {{v.mrl_reason}}
                </p>
              </b-card>
            </td>
            <td>{{ (v.mrl_create_by||{}).username||'-' }}</td>
            <td>
              <b-button varian="secondary" @click="sub=v.mrl_data">
                <b-icon icon="eye"></b-icon>
              </b-button>
            </td>
          </tr>
        </tbody>
        <tbody v-else></tbody>
      </table>
    </div>
  </template>
</div>
</template>

<script>

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

export default{
  components:{
    VTab, VTabLink, VTabContent
  },
  props:{
    logs:Array
  },
  data(){
    return {
      sub:false,
      tabSub:'sebab'
    }
  },
  watch:{
    sub(v){
      if(v){
        if(v.sebab){
          if(v.sebab.length) this.tabSub='sebab'
          else if(v.dampak.length) this.tabSub='dampak'
          else if(v.kendali.length) this.tabSub='kendali'
        }
      }
    }
  }
}
</script>