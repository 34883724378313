<template>
<div>
  <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
    <form @submit.prevent="handleSubmit(doSubmitCRUD)">
      <VStepTab :step.sync="stepTab" :last="stepLast">
        <template #link>
          <VStepLink :step.sync="stepTab" :option="1">
            Data Risiko
          </VStepLink>
          <VStepLink :step.sync="stepTab" :option="2">
            Data Sebab
          </VStepLink>
          <VStepLink :step.sync="stepTab" :option="3">
            Data Dampak
          </VStepLink>
          <VStepLink :step.sync="stepTab" :option="4">
            Data Pengendalian
          </VStepLink>
          <VStepLink :step.sync="stepTab" :option="5">
            Status
          </VStepLink>
        </template>
        <template #content>
          <VStepContent :step.sync="stepTab" :option="1">
            <template slot="title">
              Data Risiko
            </template>
            <template slot="fieldset">
              <b-row>
                <b-col md=6>
                  <b-form-group>
                    <template #label>
                      Perspektif <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect 
                      v-model="row.mr_perspektif_id" 
                      placeholder="-- Pilih Satu --"
                      :options="($parent||{}).mrPerspektif" 
                      :reduce="v=>v.value" 
                      :disabled="(!isAdd && !(moduleRole('isAdministratorSystem') || moduleRole('isAuditor')))"
                      @input="getSasaranRtu"
                    />
                    <VValidate 
                      name="Perspektif" 
                      v-model="row.mr_perspektif_id" 
                      rules="required" 
                    />
                  </b-form-group>

                  <b-form-group>
                    <template #label>
                      Unit Kerja <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect 
                      v-model="row.mr_unit_kerja" 
                      placeholder="-- Pilih Satu --"
                      :options="($parent||{}).mrUnitKerja" 
                      :reduce="v=>v.value"
                      :disabled="(!isAdd && !(moduleRole('isAdministratorSystem') || moduleRole('isAuditor')))"
                      @input="getSasaranRtu"
                    />
                    <VValidate 
                      name="Unit Kerja" 
                      v-model="row.mr_unit_kerja" 
                      rules="required" 
                    />
                  </b-form-group>

                  <b-form-group>
                    <template #label>
                      <span v-if="showSasaranRtu || (!isAdd && !(moduleRole('isAdministratorSystem') || moduleRole('isAuditor')))">
                        Sasaran RTU <span class="text-danger mr5">*</span>
                      </span>
                    </template>
                    <b-overlay
                      v-if="showSasaranRtu || (!isAdd && !(moduleRole('isAdministratorSystem') || moduleRole('isAuditor')))"
                      :show="loadingRTU"
                    >
                      <VSelect 
                        v-model="row.mr_sasaran_rtu" 
                        placeholder="-- Pilih Satu --"
                        :options="($parent||{}).sasaranRtu" 
                        :disabled="(!isAdd && !(moduleRole('isAdministratorSystem') || moduleRole('isAuditor')))"
                        :reduce="v=>v.value" 
                      />
                    </b-overlay>
                    <VValidate 
                      name="Sasaran RTU" 
                      v-model="row.mr_sasaran_rtu" 
                      rules="required" 
                    />
                  </b-form-group>

                  <b-form-group>
                    <template #label>
                      <span>
                        Bidang <span class="text-danger mr5">*</span>
                      </span>
                    </template>
                    <VSelect 
                      v-model="row.mr_bidang" 
                      placeholder="-- Pilih Satu --"
                      :options="($parent||{}).bidang" 
                      :reduce="v=>v.value" 
                    />
                    <VValidate 
                      name="Bidang" 
                      v-model="row.mr_bidang" 
                      rules="required" 
                    />
                  </b-form-group>

                </b-col>
                <b-col md=6>
                  <b-form-group>
                    <template #label>
                      Kategori <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect 
                      v-model="row.mr_category" 
                      placeholder="-- Pilih Satu --"
                      :options="getMrKategori('risiko')" 
                      :reduce="v=>v.value" 
                      @input="DetectChanged"
                    />
                    <VValidate 
                      name="Kategori" 
                      v-model="row.mr_category" 
                      rules="required" 
                    />
                  </b-form-group>
                  
                  <b-form-group>
                    <template #label>
                      Nama Risiko <span class="text-danger mr5">*</span>
                    </template>
                    <b-input
                      type="text"
                      v-model="row.mr_name"
                      @input="DetectChanged"
                    />
                    <VValidate 
                      name="Nama Risiko" 
                      v-model="row.mr_name" 
                      rules="required" 
                    />
                  </b-form-group>
                  
                  <b-form-group>
                    <template #label>
                      Muatan <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect muatan
                      v-model="row.mr_muatan" 
                      placeholder="-- Pilih Satu --"
                      :options="($parent||{}).muatan" 
                      :reduce="v=>v.value" 
                      @input="DetectChanged"
                    />
                    <VValidate 
                      name="Muatan" 
                      v-model="row.mr_muatan" 
                      rules="required" 
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </template>
          </VStepContent>
          <VStepContent :step.sync="stepTab" :option="2">
            <template slot="title">
              Data Sebab
            </template>
            <template slot="fieldset">
              <b-button 
                v-if="(row.sebab||[]).length && haveCRUD"
                variant="success" 
                class="mb-2 mt-2"
                @click="doOpenModal('SebabModal')"
              >
                Tambah Data
              </b-button>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Kategori</th>
                      <th>Deskripsi</th>
                      <th v-if="haveCRUD">Aksi</th>
                    </tr>
                  </thead>
                  <tbody v-if="!(row.sebab||[]).length">
                    <tr>
                      <th colspan="4">
                        <center>
                          <b class="d-block">Tidak Ada Data.</b>
                          <b-button 
                            v-if="haveCRUD"
                            variant="success" 
                            size="sm"
                            class="mt-2"
                            @click="doOpenModal('SebabModal')"
                          >
                            Tambah Data
                          </b-button>
                        </center>
                      </th>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(v,k) in row.sebab" :key="k">
                      <td>{{ k+1 }}</td>
                      <td>{{ mrValue(($parent||{}).mrKategori, v.mrs_category) }}</td>
                      <td>{{ v.mrs_name }}</td>
                      <td v-if="haveCRUD">
                        <b-button-group>
                          <b-button 
                            variant="warning"
                            @click="doOpenModal('SebabModal', v, k)"
                          >
                            <b-icon icon="pencil-square"/>
                          </b-button>
                          <b-button 
                            v-if="!v.is_related" 
                            variant="danger"
                            @click="row.sebab.splice(k,1)"
                          >
                            <b-icon icon="trash-fill"/>
                          </b-button>
                        </b-button-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <VValidate 
                v-if="!isDetail"
                name="Data Sebab" 
                :value="(row.sebab||[]).length?1:''"
                rules="required" 
              />
            </template>
          </VStepContent>
          <VStepContent :step.sync="stepTab" :option="3">
            <template slot="title">
              Data Dampak
            </template>
            <template slot="fieldset">
              <b-button 
                v-if="(row.dampak||[]).length && haveCRUD"
                variant="success" 
                class="mb-2 mt-2"
                @click="doOpenModal('DampakModal')"
              >
                Tambah Data
              </b-button>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Kategori</th>
                      <th>Deskripsi</th>
                      <th v-if="haveCRUD">Aksi</th>
                    </tr>
                  </thead>
                  <tbody v-if="!(row.dampak||[]).length">
                    <tr>
                      <th colspan="4">
                        <center>
                          <b class="d-block">Tidak Ada Data.</b>
                          <b-button 
                            v-if="haveCRUD"
                            variant="success" 
                            size="sm"
                            class="mt-2"
                            @click="doOpenModal('DampakModal')"
                          >
                            Tambah Data
                          </b-button>
                        </center>
                      </th>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(v,k) in row.dampak" :key="k">
                      <td>{{ k+1 }}</td>
                      <td>{{ mrValue(($parent||{}).mrKategori, v.mrd_category) }}</td>
                      <td>{{ v.mrd_name }}</td>
                      <td v-if="haveCRUD">
                        <b-button-group>
                          <b-button 
                            variant="warning"
                            @click="doOpenModal('DampakModal', v, k)"
                          >
                            <b-icon icon="pencil-square"/>
                          </b-button>
                          <b-button 
                            v-if="!v.is_related" 
                            variant="danger"
                            @click="row.dampak.splice(k,1)"
                          >
                            <b-icon icon="trash-fill"/>
                          </b-button>
                        </b-button-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <VValidate 
                v-if="!isDetail"
                name="Data Dampak" 
                :value="(row.dampak||[]).length?1:''"
                rules="required" 
              />
            </template>
          </VStepContent>
          <VStepContent :step.sync="stepTab" :option="4">
            <template slot="title">
              Data Pengendalian
            </template>
            <template slot="fieldset">
              <b-button 
                v-if="(row.kendali||[]).length && haveCRUD"
                variant="success" 
                class="mb-2 mt-2"
                @click="doOpenModal('KendaliModal')"
              >
                Tambah Data
              </b-button>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Kategori</th>
                      <th>Deskripsi</th>
                      <th>Terkait</th>
                      <th v-if="haveCRUD">Aksi</th>
                    </tr>
                  </thead>
                  <tbody v-if="!(row.kendali||[]).length">
                    <tr>
                      <th colspan="5">
                        <center>
                          <b class="d-block">Tidak Ada Data.</b>
                          <b-button 
                            v-if="haveCRUD"
                            variant="success" 
                            size="sm"
                            class="mt-2"
                            @click="doOpenModal('KendaliModal')"
                          >
                            Tambah Data
                          </b-button>
                        </center>
                      </th>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(v,k) in row.kendali" :key="k">
                      <td>{{ k+1 }}</td>
                      <td>{{ mrValue(($parent||{}).mrKategori, v.mrk_category) }}</td>
                      <td>{{ v.mrk_name }}</td>
                      <td>{{ v.mrk_terkait=='sebab'?'kemungkinan':v.mrk_terkait }}</td>
                      <td v-if="haveCRUD">
                        <b-button-group>
                          <b-button 
                            variant="warning"
                            @click="doOpenModal('KendaliModal', v, k)"
                          >
                            <b-icon icon="pencil-square"/>
                          </b-button>
                          <b-button 
                            v-if="!v.is_related" 
                            variant="danger"
                            @click="row.kendali.splice(k,1)"
                          >
                            <b-icon icon="trash-fill"/>
                          </b-button>
                        </b-button-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <VValidate 
                v-if="!isDetail"
                name="Data Pengendalian" 
                :value="(row.kendali||[]).length?1:''"
                rules="required" 
              />
            </template>
          </VStepContent>
          <VStepContent :step.sync="stepTab" :option="5">
            <template slot="title">
              Status
            </template>
            <template slot="fieldset">
              <b-row>
                <b-col md=6>
                  <b-form-group>
                    <template #label>
                      Status <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect 
                      v-model="row.mr_status" 
                      placeholder="-- Pilih Satu --"
                      :options="StatusFormPustaka" 
                      :reduce="v=>v.value"
                      :disabled="!!moduleRole('isRiskOfficer')"
                    />
                    <VValidate 
                      name="Status" 
                      v-model="row.mr_status" 
                      rules="required" 
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </VStepContent>
        </template>
        <template #action>
          <ul>
            <li v-if="stepTab>1" :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrevTab"><button type="button" :class="'btn  btn-rounded btn-previous '+(stepTab==1?'disabled':'')">Sebelumnya</button></li>
            <li v-if="stepTab!=stepLast" aria-hidden="false" aria-disabled="false" @click="doNextTab()"><button type="button" class="btn btn-rounded btn-info">Selanjutnya</button></li>

            <template v-if="!isDetail">
              <template v-if="
                haveCRUD
              ">
                <li v-if="stepTab==1 && (moduleRole('isAdministratorSystem') || moduleRole('isAuditor'))" aria-hidden="false" aria-disabled="false" @click="doNextTab(step=5)"><button type="button" class="btn btn-rounded btn-success">Ke Step Terakhir</button></li>

                <li v-if="stepTab==stepLast" aria-hidden="false" aria-disabled="false"><button type="button" class="btn btn-info btn-rounded" @click="doSubmitCRUD()">Simpan</button></li>
              </template>
            </template>

          </ul>
        </template>
      </VStepTab>
    </form>
  </ValidationObserver>

  <b-modal 
    id="SebabModal" 
    size="md"
    ok-title="Submit"
    cancel-title="Batal"
    :busy="loadingOverlay"
    no-close-on-backdrop
    no-close-on-esc
    :hide-header="true"
    centered
    @ok="doOKModal($event, 'SebabModal')"
    ok-variant="info"
  >
    <b-overlay :show="loadingOverlay">
      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
        <form @submit.prevent="handleSubmit(doOKModal($event, 'SebabModal'))">
          <b-form-group>
            <template #label>
              Kategori <span class="text-danger mr5">*</span>
            </template>
            <VSelect 
              v-model="modal.mrs_category" 
              placeholder="-- Pilih Satu --"
              :options="getMrKategori('penyebab')" 
              :reduce="v=>v.value" 
            />
            <VValidate 
              name="Kategori Risiko" 
              v-model="modal.mrs_category" 
              rules="required"
            />
          </b-form-group>
          <b-form-group>
            <template #label>
              Deskripsi <span class="text-danger mr5">*</span>
            </template>
            <b-input
              type="text"
              v-model="modal.mrs_name"
            />
            <VValidate 
              name="Deskripsi" 
              v-model="modal.mrs_name" 
              rules="required"
            />
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>

  <b-modal 
    id="DampakModal" 
    size="md"
    ok-title="Submit"
    cancel-title="Batal"
    :busy="loadingOverlay"
    no-close-on-backdrop
    no-close-on-esc
    :hide-header="true"
    centered
    @ok="doOKModal($event, 'DampakModal')"
    ok-variant="info"
  >
    <b-overlay :show="loadingOverlay">
      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
        <form @submit.prevent="handleSubmit(doOKModal($event, 'DampakModal'))">
          <b-form-group>
            <template #label>
              Kategori <span class="text-danger mr5">*</span>
            </template>
            <VSelect 
              v-model="modal.mrd_category" 
              placeholder="-- Pilih Satu --"
              :options="getMrKategori('dampak')" 
              :reduce="v=>v.value" 
            />
            <VValidate 
              name="Kategori Risiko" 
              v-model="modal.mrd_category" 
              rules="required"
            />
          </b-form-group>
          <b-form-group>
            <template #label>
              Deskripsi <span class="text-danger mr5">*</span>
            </template>
            <b-input
              type="text"
              v-model="modal.mrd_name"
            />
            <VValidate 
              name="Deskripsi" 
              v-model="modal.mrd_name" 
              rules="required"
            />
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>

  <b-modal 
    id="KendaliModal" 
    size="md"
    ok-title="Submit"
    cancel-title="Batal"
    :busy="loadingOverlay"
    no-close-on-backdrop
    no-close-on-esc
    :hide-header="true"
    centered
    @ok="doOKModal($event, 'KendaliModal')"
    ok-variant="info"
  >
    <b-overlay :show="loadingOverlay">
      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
        <form @submit.prevent="handleSubmit(doOKModal($event, 'KendaliModal'))">
          <b-form-group>
            <template #label>
              Kategori <span class="text-danger mr5">*</span>
            </template>
            <VSelect 
              v-model="modal.mrk_category" 
              placeholder="-- Pilih Satu --"
              :options="getMrKategori('pengendalian')" 
              :reduce="v=>v.value" 
            />
            <VValidate 
              name="Kategori Risiko" 
              v-model="modal.mrk_category" 
              rules="required"
            />
          </b-form-group>
          <b-form-group>
            <template #label>
              Deskripsi <span class="text-danger mr5">*</span>
            </template>
            <b-input
              type="text"
              v-model="modal.mrk_name"
            />
            <VValidate 
              name="Deskripsi" 
              v-model="modal.mrk_name" 
              rules="required"
            />
          </b-form-group>
          <b-form-group>
            <template #label>
              Terkait <span class="text-danger mr5">*</span>
            </template>
            <VSelect 
              v-model="modal.mrk_terkait" 
              placeholder="-- Pilih Satu --"
              :options="($parent||{}).mrTerkait" 
              :reduce="v=>v.value" 
            />
            <VValidate 
              name="Terkait" 
              v-model="modal.mrk_terkait" 
              rules="required"
            />
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</div>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global'

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

let _ = global._
let $ = global.jQuery

export default{
  extends:GlobalVue,
  components:{
    VStepTab, VStepLink, VStepContent
  },
  props:{
    _row:Object,
    tUser:Object
  },
  data(){
    return {
      stepTab:1,
      stepLast:5,

      modal:{},

      loadingRTU:false,
      sasaranRtu:[],
    }
  },
  methods:{
    /**
     * POPUP FUNC
     */
    doOpenModal(id, data={}, key=false){
      this.$set(this,'modal', _.assign(_.clone(data), {key:key}))
      this.$bvModal.show(id)
    },
    doOKModal(bvModalEvt, id){
      bvModalEvt.preventDefault()
      this.doSubmitModal(id)
    },
    doSubmitModal(id){
      this.$refs.VForm2.validate().then(success => {
        if (!success) { return; }

        if(this.modal.key!==false){
          switch (id) {
            case 'SebabModal':
              this.$set(this.row.sebab, this.modal.key, _.clone(this.modal))
              break;
            case 'DampakModal':
              this.$set(this.row.dampak, this.modal.key, _.clone(this.modal))
              break;
            case 'KendaliModal':
              this.$set(this.row.kendali, this.modal.key, _.clone(this.modal))
              break;
          }
        }else{
          switch (id) {
            case 'SebabModal':
              if(!this.row?.sebab) this.$set(this.row,'sebab',[])
              this.row.sebab.push(_.clone(this.modal))
              break;
            case 'DampakModal':
              if(!this.row?.dampak) this.$set(this.row,'dampak',[])
              this.row.dampak.push(_.clone(this.modal))
              break;
            case 'KendaliModal':
              if(!this.row?.kendali) this.$set(this.row,'kendali',[])
              this.row.kendali.push(_.clone(this.modal))
              break;
          }
        }

        this.$set(this,'modal',{})
        this.$bvModal.hide(id)
      })
    },

    /**
     * TAB FUNC
     */
    doNextTab(tab=false){
      this.$refs.VForm.validate().then(success => {
        if (!success) {
            return;
        }

        /* if(this.stepTab==1){
          this.loadingOverlay=true
          Gen.apiRest(
            "/do/"+this.modulePage,
            {
              data:_.assign(this.row, {
                type:'DoStepTab1'
              })
            },
            'POST'
          ).then((resp)=>{
            this.loadingOverlay=false
            if(resp.data.all_uploaded=='Y'){
              this.stepTab+=1
              $('html, body').animate({scrollTop:0}, '500');
            }
          })

        }else{ */

          if(this.row.mr_status){
            if(
              !_.filter(this.StatusFormPustaka, (v)=>{
                return v.value == this.row.mr_status
              }).length
            ){
              this.$set(this.row, 'mr_status', null)
            }
          }

          if(tab) this.stepTab=tab
          else this.stepTab+=1

          $('html, body').animate({scrollTop:0}, '500');
        //}
      });
    },
    doPrevTab(){
      if(this.stepTab<=1) return;
      this.stepTab-=1
      $('html, body').animate({scrollTop:0}, '500');
    },

    DetectChanged(){
      if(this.moduleRole('isRiskOfficer')){
        this.row.mr_status='purpose'
      }
    },
    getMrKategori(type){
      return _.filter((this.$parent||{}).mrKategori, (v)=>{
        return v.flag==type
      })
    },
    getSasaranRtu(){
      const {mr_unit_kerja, mr_perspektif_id} = this.row;
      const payload = {
        data: {
          type: "getSasaranRtu",
          mr_unit_kerja,
          mr_perspektif_id 
        }
      }
      if(this.showSasaranRtu){
        this.loadingRTU=true
        Gen.apiRest("/do/" + this.$route.name, payload, "POST").then(
          res => {
            this.loadingRTU=false
            this.$parent.sasaranRtu = res.data.data
          }
        ).catch(err => {
          this.loadingRTU=false
          console.log(err)
        })
      }else {
        this.sasaranRtu = [];
      }
      this.$set(this.row, 'mr_sasaran_rtu', null)
    }
  },
  computed: {
    isDetail(){
      return (this.$route.query||{}).view=='detail'
    },
    haveCRUD(){
      return (
        !(this.moduleRole('isRiskOfficer') && this.row.old_mr_status=='purpose') &&
        !this.moduleRole('ViewOnly') &&
        !this.isDetail
      )
    },
    showSasaranRtu(){
      return this.row.mr_perspektif_id && this.row.mr_unit_kerja
    },
    StatusFormPustaka(){
      if(this.moduleRole('isRiskOfficer')){
        let status = [
          {label:'Purpose', value:'purpose'}
        ]

        if(!this.isAdd){
          status = _.union(status, [
            {label:'Approved', value:'approved'},
            {label:'Rejected', value:'rejected'},
            {label:'Obsolete', value:'obsolete'},
          ])
        }
        
        return status

      }else{
        return [
          {label:'Active', value:'approved'},
          {label:'Draft', value:'draft'},
          {label:'Obsolete', value:'obsolete'},
        ]
      }
    }
  },
  watch:{
    _row(v){
      this.$set(this,'row',v)
      if(this.moduleRole('isRiskOfficer')){
        this.$set(this.row,'mr_status','purpose')
      }
    }
  }
}
</script>