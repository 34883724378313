<template>
<div class="mt-2">
  <VTab>
    <template slot="link">
      <VTabLink :step.sync="tab" option="fu">Follow Up</VTabLink>
      <VTabLink :step.sync="tab" option="logs">Logs</VTabLink>
    </template>
    <template slot="content">
      <VTabContent :step.sync="tab" option="fu">
        <template v-if="modal.mr_status=='purpose'">
          
          <b-card 
              v-if="modal.mr_req_name || modal.mr_req_muatan || modal.mr_req_category"
            title="Data Pustaka Risiko" class="mt-2"
          >
            <div class="table-reponsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Data Sebelumnya</th>
                    <th>Data Yang Diajukan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="modal.mr_req_name">
                    <th><b>Nama Risiko</b></th>
                    <td>{{ modal.mr_update_date ? modal.mr_name : '-' }}</td>
                    <td :class="{
                      'bg-warning':modal.mr_update_date,
                      'bg-success':!modal.mr_update_date,
                      'text-semibold text-white':1
                    }">
                      {{ modal.mr_req_name }}
                    </td>
                  </tr>
                  <tr v-if="modal.mr_req_muatan">
                    <th><b>Muatan</b></th>
                    <td>{{ modal.mr_update_date ? modal.mr_muatan : '-' }}</td>
                    <td :class="{
                      'bg-warning':modal.mr_update_date,
                      'bg-success':!modal.mr_update_date,
                      'text-semibold text-white':1
                    }">
                      {{ modal.mr_req_muatan }}
                    </td>
                  </tr>
                  <tr v-if="modal.mr_req_category">
                    <th><b>Kategori</b></th>
                    <td>
                      {{ modal.mr_update_date ? mrValue(mrKategori, modal.mr_category) : '-' }}
                    </td>
                    <td :class="{
                      'bg-warning':modal.mr_update_date,
                      'bg-success':!modal.mr_update_date,
                      'text-semibold text-white':1
                    }">
                      {{ mrValue(mrKategori, modal.mr_req_category) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>

          <VTab>
            <template slot="link">
              <VTabLink :step.sync="tabSub" option="sebab">Sebab</VTabLink>
              <VTabLink :step.sync="tabSub" option="dampak">Dampak</VTabLink>
              <VTabLink :step.sync="tabSub" option="kendali">Pengendalian</VTabLink>
            </template>
            <template slot="content">
              <VTabContent :step.sync="tabSub" :option="tabSub">

                <VTab>
                  <template slot="link">
                    <VTabLink :step.sync="tabUpdate" option="berubah">Data Yang Berubah</VTabLink>
                    <VTabLink :step.sync="tabUpdate" option="tidak_berubah">Data Tidak Berubah</VTabLink>
                  </template>
                  <template slot="content">

                    <VTabContent :step.sync="tabUpdate" option="berubah">
                      <b-card 
                        :title="'Data '+heading" 
                        class="mt-2" 
                        style="max-height:800px;overflow:auto;"
                      >
                        <div 
                           v-if="(modal[tabSub]||[]).length"
                          class="table-reponsive"
                        >
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th style="width:10px;max-width:10px;"></th>
                                <th></th>
                                <th>Data Sebelumnya</th>
                                <th>Data Yang Diajukan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(v,k) in (modal[tabSub]||[])">
                                <tr :key="k+'a'"
                                  :class="{
                                    'bg-warning':v[field+'_status_update']=='U',
                                    'bg-success':v[field+'_status_update']=='N',
                                    'bg-danger':v[field+'_status_update']=='D',
                                    'text-semibold text-white':1
                                  }"
                                >
                                  <td>
                                    <b-icon v-if="v[field+'_status_update']=='U'" font-scale="1" icon="pencil-square" />
                                    <b-icon v-else-if="v[field+'_status_update']=='D'" font-scale="1" icon="dash-square-fill" />
                                    <b-icon v-else font-scale="1" icon="plus-square-fill" />
                                  </td>
                                  <td colspan="3">
                                    Kode: {{ v[field+'_code'] }}
                                  </td>
                                </tr>
                                <tr v-if="v[field+'_req_name'] || v[field+'_status_update']==='D'" :key="k+'b'">
                                  <td></td>
                                  <th><b>Nama Risiko</b></th>
                                  <td>
                                    {{ v[field+'_status_update']!='N' ? v[field+'_name'] : '-' }}
                                  </td>
                                  <td 
                                    :class="{
                                      'bg-warning':v[field+'_status_update']=='U',
                                      'bg-success':v[field+'_status_update']=='N',
                                      'bg-danger':v[field+'_status_update']=='D',
                                      'text-semibold text-white':1
                                    }"
                                  >
                                    <template v-if="v[field+'_status_update']=='D'">
                                      X
                                    </template>
                                    <template v-else>
                                      {{ v[field+'_req_name']}}
                                    </template>
                                  </td>
                                </tr>
                                <tr v-if="v[field+'_req_category'] || v[field+'_status_update']=='D'" :key="k+'b'">
                                  <td></td>
                                  <th><b>Kategori</b></th>
                                  <td>
                                    {{ v[field+'_status_update']!='N' ? mrValue(mrKategori, v[field+'_category']) : '-' }}
                                  </td>
                                  <td 
                                    :class="{
                                      'bg-warning':v[field+'_status_update']=='U',
                                      'bg-success':v[field+'_status_update']=='N',
                                      'bg-danger':v[field+'_status_update']=='D',
                                      'text-semibold text-white':1
                                    }"
                                  >
                                    <template v-if="v[field+'_status_update']=='D'">
                                      X
                                    </template>
                                    <template v-else>
                                      {{ mrValue(mrKategori, v[field+'_req_category']) }}
                                    </template>
                                  </td>
                                </tr>

                                <template v-if="tabSub=='kendali'">
                                  <tr v-if="v[field+'_req_terkait'] || v[field+'_status_update']=='D'" :key="k+'b'">
                                    <td></td>
                                    <th><b>Terkait</b></th>
                                    <td>
                                      {{ v[field+'_status_update']!='N' ? (v[field+'_terkait']=='sebab'?'kemungkinan':v[field+'_terkait']) : '-' }}
                                    </td>
                                    <td 
                                      :class="{
                                        'bg-warning':v[field+'_status_update']=='U',
                                        'bg-success':v[field+'_status_update']=='N',
                                        'bg-danger':v[field+'_status_update']=='D',
                                        'text-semibold text-white':1
                                      }"
                                    >
                                      <template v-if="v[field+'_status_update']=='D'">
                                        X
                                      </template>
                                      <template v-else>
                                        {{ v[field+'_req_terkait']=='sebab'?'kemungkinan':v[field+'_req_terkait'] }}
                                      </template>
                                    </td>
                                  </tr>  
                                </template>

                              </template>
                            </tbody>
                          </table>
                        </div>
                        <div 
                          v-else
                          class="wrap_no_data" align="center"
                        >
                          <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/>
                          <h3>{{ Config.wording.no_data_listed }}</h3>
                        </div>
                      </b-card>

                    </VTabContent>

                    <VTabContent :step.sync="tabUpdate" option="tidak_berubah">
                      <b-card 
                        :title="'Data '+heading+' Yang Tidak Berubah'" 
                        class="mt-2" 
                      >
                        <b-table
                          :primary-key="field+'_id'"
                          :fields="TbFields"
                          :items="modal[tabSub+'NoChange']||[]"
                          :per-page="TbPerPage"
                          :current-page="TbCurrentPage"
                          show-empty
                          responsive
                        >
                          <template #empty>
                            <div class="wrap_no_data" align="center">
                              <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/>
                              <h3>{{ Config.wording.no_data_listed }}</h3>
                            </div>
                          </template>
                          <template #cell(number)="data">
                            {{data.index+1}}
                          </template>

                          <template #cell(mrd_category)="data">
                            {{ mrValue(mrKategori, data.value)||'-' }}
                          </template>
                          <template #cell(mrs_category)="data">
                            {{ mrValue(mrKategori, data.value)||'-' }}
                          </template>
                          <template #cell(mrk_category)="data">
                            {{ mrValue(mrKategori, data.value)||'-' }}
                          </template>

                          <template #cell(mrk_terkait)="data">
                            {{ data.value=='sebab'?'kemungkinan':data.value }}
                          </template>

                        </b-table>
                        <b-pagination
                          v-if="(modal[tabSub+'NoChange']||[]).length>TbPerPage"
                          :total-rows="(modal[tabSub+'NoChange']||[]).length"

                          v-model="TbCurrentPage"
                          :per-page="TbPerPage"
                        ></b-pagination>
                      </b-card>
                    </VTabContent>

                  </template>
                </VTab>
              </VTabContent>
            </template>
          </VTab>
              
          <div 
            class="card-footer"
            style="background:#fff"
          >
            <slot name="action"></slot>
          </div>
        </template>
      </VTabContent>
      <VTabContent :step.sync="tab" option="logs">

        <MrPustakaRisikoLogs :logs="modal.logs" />

      </VTabContent>
    </template>
  </VTab>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'
import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

import MrPustakaRisikoLogs from '@/views/MrPustakaRisikoLogs'

export default{
  extends: GlobalVue,
  components:{
    VTab, VTabLink, VTabContent,
    MrPustakaRisikoLogs
  },
  props:{
    modal:Object,
    mrKategori:Array,
  },
  data(){
    return {
      tab:"fu",
      tabSub:'sebab',
      tabUpdate:'berubah',
      TbPerPage:10,
      TbCurrentPage:1,
    }
  },
  computed:{
    field(){
      let f = "mrs"
      if(this.tabSub=='dampak') f = 'mrd'
      else if(this.tabSub=='kendali') f = 'mrk'

      return f
    },
    TbFields(){
      const field = this.field
      let fields = [
        { key:'number', label:'#' },
        { key:'number', label:'Kode' },
        { key: field+'_name', label:'Nama' },
        { key: field+'_category', label:'Kategori' },
      ]

      if(this.tabSub=='kendali'){
        fields.push({
          key:field+'_terkait', label:'Terkait'
        })
      }

      return fields
    },
    heading(){
      let f = "Sebab"
      if(this.tabSub=='dampak') f = 'Dampak'
      else if(this.tabSub=='kendali') f = 'Pengendalian'

      return f
    }
  }
}
</script>