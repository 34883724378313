<template>
<div>
  <div class="container-fluid">
    <PageTitle />
    
    <template v-if="isList">
      <!-- :statusOptions="status" -->
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"
        :statusOptions="[]"
      > 
        <template #filters>
           <b-col md=4>
            <VSelect 
              v-model="filter.unit_kerja" 
              placeholder="-- Semua Unit Kerja --"
              :options="mrUnitKerja" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
          <b-col md=3>
            <VSelect 
              v-model="filter.status" 
              placeholder="-- Semua Status --"
              :options="filterStatus" 
              :reduce="v=>v.value" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #count="data">
          <ul>
            <li v-for="(value, index) in Object.keys(data.scope.value)" :key="index">
              {{ value }} : {{ data.scope.value[value] }}
            </li>
          </ul>
        </template>
        <template #mb_name="data">
          {{data.scope.value || '-'}}
        </template>
        <template #mr_status="data">
          <LabelStatus 
            :status="data.scope.item[statusKey]" 
            type="sasaranRtu" 
            class="d-block"
          />
          <LabelStatus
            :status="data.scope.item.mr_is_active"
            type="pustakaRisiko"
            class="d-block mt-1"
          />
        </template>
        <template #aksi="data">
          <b-button-group>
            <template v-if="
              data.scope.item.mr_status=='purpose' && (moduleRole('isAdministratorSystem') || moduleRole('isAuditor')) && !moduleRole('ViewOnly')
            ">
              <b-button
                size="sm"
                variant="success"
                @click="doOpenModal('ApprovalModal', data.scope.item)"
              >
                <div v-b-tooltip.hover="'Follow Up'">
                  <b-icon icon="gear-fill"/>
                </div>
              </b-button>
            </template>
            <template 
              v-else-if="
                ((moduleRole('isRiskOfficer') && ['purpose','draft','obsolete','rejected'].indexOf(data.scope.item.mr_status)<=-1 && data.scope.item.mr_office_id==tUser.officeId)
                 ||
                 moduleRole('isAdministratorSystem'))
                 && !moduleRole('ViewOnly')
              "
            >
              <b-dropdown no-flip>
                <template #button-content>
                  <i class="icon-menu"></i>
                </template>
                <b-dropdown-item 
                  :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}"
                >
                  Ubah Data
                </b-dropdown-item>
                <b-dropdown-item 
                  v-if="data.scope.item.mr_is_from_ro=='Y'"
                  @click="doOpenModal('LogsModal', data.scope.item)"
                >
                  Approval Logs
                </b-dropdown-item>
                <b-dropdown-item 
                  v-if="moduleRole('isAdministratorSystem') || moduleRole('isAuditor')"
                  @click="doDelete(data.scope.index, data.scope.item)"
                >
                  Hapus
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <span v-else>
              <b-button varian="secondary" :to="{name:modulePage, params:{pageSlug:data.scope.item[idKey]}, query:{view:'detail'}}">
                <div v-b-tooltip.hover="'Lihat'">
                  <b-icon icon="eye"/>
                </div>
              </b-button>
            </span>
          </b-button-group>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">
          {{isAdd?'Tambah':'Ubah'}} {{pageTitle}}
        </h5>
        
        <b-alert 
          v-if="
            row.old_mr_status == 'revise' && 
            moduleRole('isRiskOfficer')  && 
            row.revise_reason
          "
          show
          variant="danger"
        >
          <span style="font-weight: 700;">Catatan Revisi : </span>
          {{row.revise_reason}}
        </b-alert>

        <MrPustakaRisikoForm :_row="row" :tUser="tUser||{}" />
      </div>
    </div>

    <b-modal 
      id="LogsModal" 
      title="Approval Logs"
      size="lg"
      ok-only
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
      :busy="loadingOverlay"
    >
      <b-overlay :show="loadingOverlay">
        <template v-if="Object.keys(modal).length">
          
          <MrPustakaRisikoLogs :logs="modal.logs" />

        </template>
      </b-overlay>
    </b-modal>
    
    <b-modal 
      id="ApprovalModal" 
      title="Risiko Approval"
      size="lg"
      ok-only
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
      :busy="loadingOverlay"
    >
      <b-overlay :show="loadingOverlay">
        <template v-if="Object.keys(modal).length"> 
          <MrPustakaRisikoApproval 
            :modal="modal"
            :mrKategori="mrKategori" 
          >
            <template #action>
              <b-form-group 
                v-if="modal.open_reason" 
                label="Alasan*"
              >
                <b-textarea 
                  v-model="modal.reason" 
                  placeholder="Tulis alasan disini..."
                />
              </b-form-group>
              <div class="form-action text-center">
                <button 
                  type="button" 
                  class="btn btn-rounded btn-danger mr-1"
                  @click="doSubmitModal('Reject')"
                >
                  Reject
                </button>
                <button
                  type="button" 
                  class="btn btn-rounded btn-warning mr-1"
                  @click="doSubmitModal('Revise')"
                >
                  Revise
                </button>
                <button
                  type="button" 
                  class="btn btn-rounded btn-success mr-1"
                  @click="doSubmitModal('Approve')"
                >
                  Approved
                </button>
              </div>
            </template>
          </MrPustakaRisikoApproval>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import Gen from '@libs/Gen.js'

import MrPustakaRisikoForm from '@/views/MrPustakaRisikoForm'
import MrPustakaRisikoApproval from '@/views/MrPustakaRisikoApproval'
import MrPustakaRisikoLogs from '@/views/MrPustakaRisikoLogs'

let _ = global._
let Swal = global.Swal
export default{
  extends:GlobalVue,
  components:{
    PageTitle, CardList,
    MrPustakaRisikoForm,
    MrPustakaRisikoLogs,
    MrPustakaRisikoApproval
  },
  data(){
    return {
      idKey:'mr_id',
      tUser:{},
      statusKey:'mr_status',
      fields:[
        { key: "select", label: "" },
        { key:'number', label:'#' },
        { key:'mr_code', label:'Kode Risiko' },
        { key:'mr_name', label:'Nama Risiko' },
        { key:'msr_description', label:'Sasaran RTU' },
        { key:'mb_name', label:'Bidang', is_custom: true },
        { key:'mr_muatan', label:'Muatan' },
        { key:'count', label:'Jumlah Data', is_custom: true, thClass: 'w_100' },
        { key:'mr_status', label:'Status', is_custom:true },
        { key:'aksi', is_custom:true, thClass: 'w_100' },
      ],
      
      modal:{},

      mrPerspektif:[],
      mrUnitKerja: [],
      status: [],
      muatan: [],
      mrSasaranRtu: [],
      sasaranRtu: [],
      mrKategori: [],
      mrTerkait: [],
      bidang: [],
      filterStatus: [],
      loadingRTU:false,
      approvalButtonDisabled: false,
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    doOpenModal(id, data){
      this.tab="fu"
      this.loadingOverlay=true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data:{
            type:'view',
            id: data[this.idKey]
          }
        },
        'POST'
      ).then((resp)=>{
        this.loadingOverlay=false
        this.$set(this,'input',{})
        this.$set(this,'modal', Object.assign(_.clone(data), resp.data))
        if(this.modal.sebab){
          if(this.modal.sebab.length) this.tabSub='sebab'
          else if(this.modal.dampak.length) this.tabSub='dampak'
          else if(this.modal.kendali.length) this.tabSub='kendali'
        }
        this.$bvModal.show(id)
      })
    },
    doSubmitModal(id){
      if(['Revise','Reject'].indexOf(id)>-1 && !this.modal.reason){
        
        if(this.modal.open_reason){
          Swal.fire({
            icon:'warning',
            title:'Wajib isi Alasan!'
          })
        }

        return this.$set(this.modal,'open_reason',true)
      }else{
        this.$set(this.modal,'open_reason',false)
      }
      this.loadingOverlay = true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data:{
            type:"Do"+id,
            id:this.modal[this.idKey],
            reason:this.modal.reason||""
          }
        },
        'POST'
      ).then((res)=>{
        this.loadingOverlay=false
        this.$bvModal.hide(id)
        this.$bvModal.hide('ApprovalModal')
        let resp = res.data
        if(Object.keys(this.$route.query).length == 1){
          this.apiGet()   
        }else {
          this.$set(this, 'filter', {})
          this.$router.push({name:this.modulePage, query:{tab:this.$route.query.tab}})
        }
        global.Swal.fire({
          title: resp.title,
          text: resp.message||"",
          icon: resp.status,
        })
      })
    },

    changeStatus(status, id, statusOld=""){
      if(status.value===statusOld) return
      global.Swal.fire({
        title: `Ingin mengubah status data menjadi ${status.label}?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, ${status.label}!`,
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(res => {
          if(res.value){
            this.loadingOverlay = true
            this.approvalButtonDisabled = true

            Gen.apiRest(
              "/do/"+this.modulePage,
              {data: {type: "status", id: id, status: status.value}},
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                global.Swal.fire({
                  title: `Status data berubah menjadi ${status.label}.`,
                  icon: 'success',
                })
                this.apiGet()

                this.$bvModal.hide('ApprovalModal')
                this.approvalButtonDisabled = false

                if(this.$route.params.pageSlug) this.$router.push({name: this.$route.name})
              })
              .catch(()=>{ 
                this.loadingOverlay = false
              })
          }
        })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
  },
}
</script>