var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid"},[_c('PageTitle'),(_vm.isList)?[_c('card-list',{attrs:{"filter":_vm.filter,"dataListing":_vm.data,"idKey":_vm.idKey,"fields":_vm.fields,"statusOptions":[]},on:{"update:filter":function($event){_vm.filter=$event},"update:dataListing":function($event){_vm.data=$event},"update:data-listing":function($event){_vm.data=$event}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-col',{attrs:{"md":"4"}},[_c('VSelect',{attrs:{"placeholder":"-- Semua Unit Kerja --","options":_vm.mrUnitKerja,"reduce":function (v){ return String(v.value); }},on:{"input":_vm.doFilter},model:{value:(_vm.filter.unit_kerja),callback:function ($$v) {_vm.$set(_vm.filter, "unit_kerja", $$v)},expression:"filter.unit_kerja"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('VSelect',{attrs:{"placeholder":"-- Semua Status --","options":_vm.filterStatus,"reduce":function (v){ return v.value; }},on:{"input":_vm.doFilter},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1)]},proxy:true},{key:"count",fn:function(data){return [_c('ul',_vm._l((Object.keys(data.scope.value)),function(value,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(value)+" : "+_vm._s(data.scope.value[value])+" ")])}),0)]}},{key:"mb_name",fn:function(data){return [_vm._v(" "+_vm._s(data.scope.value || '-')+" ")]}},{key:"mr_status",fn:function(data){return [_c('LabelStatus',{staticClass:"d-block",attrs:{"status":data.scope.item[_vm.statusKey],"type":"sasaranRtu"}}),_c('LabelStatus',{staticClass:"d-block mt-1",attrs:{"status":data.scope.item.mr_is_active,"type":"pustakaRisiko"}})]}},{key:"aksi",fn:function(data){return [_c('b-button-group',[(
              data.scope.item.mr_status=='purpose' && (_vm.moduleRole('isAdministratorSystem') || _vm.moduleRole('isAuditor')) && !_vm.moduleRole('ViewOnly')
            )?[_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.doOpenModal('ApprovalModal', data.scope.item)}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Follow Up'),expression:"'Follow Up'",modifiers:{"hover":true}}]},[_c('b-icon',{attrs:{"icon":"gear-fill"}})],1)])]:(
                ((_vm.moduleRole('isRiskOfficer') && ['purpose','draft','obsolete','rejected'].indexOf(data.scope.item.mr_status)<=-1 && data.scope.item.mr_office_id==_vm.tUser.officeId)
                 ||
                 _vm.moduleRole('isAdministratorSystem'))
                 && !_vm.moduleRole('ViewOnly')
              )?[_c('b-dropdown',{attrs:{"no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"icon-menu"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{name:_vm.$route.name, params:{pageSlug:data.scope.item[_vm.idKey]}}}},[_vm._v(" Ubah Data ")]),(data.scope.item.mr_is_from_ro=='Y')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.doOpenModal('LogsModal', data.scope.item)}}},[_vm._v(" Approval Logs ")]):_vm._e(),(_vm.moduleRole('isAdministratorSystem') || _vm.moduleRole('isAuditor'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.doDelete(data.scope.index, data.scope.item)}}},[_vm._v(" Hapus ")]):_vm._e()],1)]:_c('span',[_c('b-button',{attrs:{"varian":"secondary","to":{name:_vm.modulePage, params:{pageSlug:data.scope.item[_vm.idKey]}, query:{view:'detail'}}}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Lihat'),expression:"'Lihat'",modifiers:{"hover":true}}]},[_c('b-icon',{attrs:{"icon":"eye"}})],1)])],1)],2)]}}],null,false,1076331934)})]:_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title mb-3"},[_vm._v(" "+_vm._s(_vm.isAdd?'Tambah':'Ubah')+" "+_vm._s(_vm.pageTitle)+" ")]),(
            _vm.row.old_mr_status == 'revise' && 
            _vm.moduleRole('isRiskOfficer')  && 
            _vm.row.revise_reason
          )?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("Catatan Revisi : ")]),_vm._v(" "+_vm._s(_vm.row.revise_reason)+" ")]):_vm._e(),_c('MrPustakaRisikoForm',{attrs:{"_row":_vm.row,"tUser":_vm.tUser||{}}})],1)]),_c('b-modal',{attrs:{"id":"LogsModal","title":"Approval Logs","size":"lg","ok-only":"","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":true,"busy":_vm.loadingOverlay}},[_c('b-overlay',{attrs:{"show":_vm.loadingOverlay}},[(Object.keys(_vm.modal).length)?[_c('MrPustakaRisikoLogs',{attrs:{"logs":_vm.modal.logs}})]:_vm._e()],2)],1),_c('b-modal',{attrs:{"id":"ApprovalModal","title":"Risiko Approval","size":"lg","ok-only":"","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":true,"busy":_vm.loadingOverlay}},[_c('b-overlay',{attrs:{"show":_vm.loadingOverlay}},[(Object.keys(_vm.modal).length)?[_c('MrPustakaRisikoApproval',{attrs:{"modal":_vm.modal,"mrKategori":_vm.mrKategori},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.modal.open_reason)?_c('b-form-group',{attrs:{"label":"Alasan*"}},[_c('b-textarea',{attrs:{"placeholder":"Tulis alasan disini..."},model:{value:(_vm.modal.reason),callback:function ($$v) {_vm.$set(_vm.modal, "reason", $$v)},expression:"modal.reason"}})],1):_vm._e(),_c('div',{staticClass:"form-action text-center"},[_c('button',{staticClass:"btn btn-rounded btn-danger mr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.doSubmitModal('Reject')}}},[_vm._v(" Reject ")]),_c('button',{staticClass:"btn btn-rounded btn-warning mr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.doSubmitModal('Revise')}}},[_vm._v(" Revise ")]),_c('button',{staticClass:"btn btn-rounded btn-success mr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.doSubmitModal('Approve')}}},[_vm._v(" Approved ")])])]},proxy:true}],null,false,3122621938)})]:_vm._e()],2)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }